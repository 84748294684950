html {
    font-family: 'Asap', sans-serif;
    font-size: 14px;
}

::-webkit-scrollbar { width: 6px; }
::-webkit-scrollbar-track { background: #ddd; }
::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 3px;
    &:hover { background: #525252; }
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    color: #333;
    background-color: ghostwhite;
    line-height: 1.5rem;
    margin: 0 auto;
    scrollbar-width: thin;
    &.fixed {
        overflow: hidden;
        .content.container { opacity: .42; }
    }
}

.page,
.post { margin-bottom: 0; }

.content {
    padding-top: 6rem;
    max-width: 90%;
    .container { transition: all .3s ease; }
    img {
        border-radius: 4px;
        box-shadow: 2px 2px 6px rgba(#000, .2);
    }
    ul {
        padding-left: 1rem;
        list-style-type: none;
        text-indent: -.6rem;
        li {
            &::before { content: '\2023 \2009'; }
            ul {
                padding-left: 1.5rem;
                list-style-type: none;
                margin-bottom: .25rem;
                li::before { content: '\2023 \2009'; }
            }
        }
         p {
            text-indent: 0;
            padding-left: 1rem;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 { color: #202124; }
    h3.trabajo {
        font-style: italic;
        color: darkgrey;
        font-weight: normal;
        line-height: 2rem;
    }
}

@media (max-width: 48em) {
    .content.container {
        padding-left: 0;
        padding-right: 0;
        padding-top: 5rem;
    }
}

@media (min-width: 48em) {

    .content {
        padding-top: 2rem;
        max-width: 60rem;
        margin-left: 2rem;
    }
    .sidebar { width: 15rem; }

    html { font-size: 13px; }

}

a {
    color: deepskyblue;
    transition: color .3s ease;
    &:focus,
    &:hover {
        color: #1e1e20;
        text-decoration: none;
    }
}

abbr {
    font-size: 100%;
    color: inherit;
    font-weight: inherit;
    text-transform: initial;
}

@media (min-width: 58em) {
    abbr[title] {
        cursor: help;
        border-bottom: 1px dotted #ccc;
    }
}

h1 {
    font-size: 2.8125rem;
    line-height: 3.5625rem;
    margin-top: 2.375rem;
    margin-bottom: 1.1875rem;
    + h2 { margin-top: 0; }
    + .lead { font-style: italic; }
}

h2 {
    font-size: 2rem;
    line-height: 2.375rem;
    margin-top: 2.375rem;
    margin-bottom: 1.1875rem;
}

h3 {
    font-size: 1.4375rem;
    line-height: 2.375rem;
    margin-top: 1.1875rem;
    margin-bottom: 1.1875rem;
}

h4 {
    font-size: 1rem;
    line-height: 1.1875rem;
    margin-top: 1.1875rem;
    margin-bottom: 1.1875rem;
}

.header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

p,
ul,
ol,
pre,
table,
blockquote {
    margin-top: 1.1875rem;
    margin-bottom: 1.1875rem;
}

blockquote p { margin: 0; }


// Sanitation

hr {
    border: 1px solid;
    margin: -1px 0;
}

ul,
ol {
    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
    }
}

b,
strong,
em,
small,
code { line-height: 1; }

sup,
sub {
    vertical-align: baseline;
    position: relative;
    top: -.4em;
}

sub { top: .4em; }


// Sidebar

.sidebar {
    color: #5f6368;
    background-color: ghostwhite;
    overflow-y: auto;
    text-align: left;
    height: 100%;
    scrollbar-width: thin;
    a { color: #333; }
    .sidebar-nav-title {
        margin: .75rem 0 0;
        font-size: 1rem;
        line-height: 1.4rem;
    }
}

.sidebar-about {
    h1 {
        font-family: 'Asap', sans-serif;
        font-size: 2rem;
        line-height: 2rem;
        margin-top: 0;
        a {
            color: #202124;
            &:hover {
                color: deepskyblue;
                text-decoration: none;
            }
        }
    }
}

.sidebar-nav-item {
    line-height: 1.4;
    &:hover,
    &:focus { color: deepskyblue; }
    &.active {
        color: deeppink;
        font-weight: 700;
        &::before { content: '\2023 \2009'; }
    }
}

.empleador {
    font-size: .85rem;
    font-weight: 700;
    color: deepskyblue;
    line-height: .85rem;
}

.small-text {
    font-size: .7rem;
    line-height: .9rem;
    margin-top: 1.1875rem;
    p { margin: 0; }
}

.subtle { color: #ddd; }

.comitente,
.tags,
.etiquetas { font-style: italic; }


// Fotorama

.carousel { overflow: visible; }
.fotorama__stage {
    border-radius: 4px;
    box-shadow: 2px 2px 6px rgba(#000, .2);
}
.fotorama__nav-wrap { margin-top: .5rem; }
.fotorama__nav__frame.fotorama__active .fotorama__dot { border-width: 2px; }
.fotorama__nav::before,
.fotorama__nav::after,
.fotorama__stage::before,
.fotorama__stage::after { background-image: initial; }


// Contacto

.contacto {
    max-width: 500px;
    textarea {
        resize: vertical;
        min-height: 84px;
        max-height: 250px;
    }
    input[type='text'],
    input[type='email'],
    textarea {
        box-sizing: border-box;
        outline: none;
        display: block;
        width: 100%;
        padding: 7px;
        border: 0;
        border-bottom: 1px solid #ddd;
        background: transparent;
        margin-bottom: 10px;
        height: 45px;
        font-family: 'Asap', sans-serif;
    }
    input[type='submit'] {
        cursor: pointer;
        font-size: .85rem;
        padding: 8px 18px;
        font-family: 'Asap', sans-serif;
        margin-top: 8px;
    }
}


// Tweaks

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    border-radius: 4px;
    &.ar-75 { padding-bottom: 75%; }
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

video {
    width: 100%;
    box-shadow: 2px 2px 6px rgba(#000, .2);
    border-radius: 4px;
}

abbr[title],
acronym[title] { text-decoration: none; }

.fade-in {
    animation: fade-in 2s ease;
    visibility: visible;
    backface-visibility: hidden;
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
