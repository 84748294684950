#layout,
#menu,
a.menu-link,
.content { transition: all .2s ease; }

#layout {
    position: relative;
    padding-left: 0;
    transition: all .2s ease;
    left: 0;
    &.active {
        #menu {
            left: 15rem;
            width: 15rem;
            box-shadow: 2px 2px 2px rgba(#000, .2);
        }
        .menu-link {
            left: 15rem;
            span {
                background-color: unset;
                &::before {
                    margin-top: 0;
                    transform: rotate(45deg);
                }
                &::after {
                    margin-top: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

#menu {
    margin-left: -15rem;
    width: 15rem;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.menu-link {
    width: 100%;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    background: rgba(#000, .7);
    font-size: 10px;
    z-index: 10;
    box-sizing: content-box;
    padding: 1.5rem 1rem;
    span {
        position: relative;
        display: block;
        background-color: #fff;
        width: 1.5rem;
        height: .2em;
        &::before,
        &::after {
            transition: all .3s ease-in-out;
            background-color: #fff;
            width: 1.5rem;
            height: .2em;
            position: absolute;
            content: '';
        }
        &::before { margin-top: -.6em; }
        &::after { margin-top: .6em; }
    }
}

@media (min-width: 48em) {

    #layout {
        padding-left: 15rem;
        left: 0;
    }
    #menu { left: 15rem; }
    .menu-link {
        position: fixed;
        left: 15rem;
        display: none;
    }
    #layout.active .menu-link { left: 15rem; }

}

@media (max-width: 48em) {

    #layout.active { left: 15rem; }

}
